.dettagli {
  .textTitle {
    cursor: default !important;
    font-size: 18px;
    font-weight: bold !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .dato {
    cursor: default;
    margin-bottom: 30px;
    word-wrap: break-word;
    padding-left: 10px;
    padding-right: 10px;  }
  .list {
    overflow: auto;
    background-color: #f0f0f0;
    border-radius: 20px;
    @media screen and (max-width: 460px) {
      max-width: 400px;
    }
    .attivitaDettagli {
      margin-left: 30px;
      padding: 20px;
      margin-top: 10px;
      cursor: default;
      margin-bottom: 15px;
      margin-top: 15px;
      color: white;
      width: 400px;
      border-radius: 90px;
      opacity: 1;
      float: left;
      text-transform: none;
      font-weight: bold;
      box-shadow: 0px 3px 6px #000000;
      text-decoration: none;
      text-align: center;
      @media screen and (max-width: 900px) {
        width: 690px;
        margin-left: 10px;
      }
      @media screen and (max-width: 460px) {
        width: 300px;
        margin-left: 10px;
      }
      @media screen and (max-width: 360px) {
        width: 260px;
        margin-left: 5px;
      }
    }
  }
  .disciplineDettagli {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-right: 25px;
    color: #000000;
    border-radius: 90px;
    opacity: 1;
    float: left;
    text-transform: none;
    font-weight: bold;
    box-shadow: "0px 3px 6px #000000";
    text-decoration: none;
  }
}
